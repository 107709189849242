import cn from 'classnames';
import Shared from './Shared';
const themeShared = {
    Button: {
        Button: {
            button: Shared.Button.base,
            button_small: Shared.Button.small,
            button_large: Shared.Button.large
        },
        BaseButton: {
            button: Shared.Button.base,
            button_small: Shared.Button.small
        },
        ActionButton: {
            button: Shared.Button.base,
            button_small: Shared.Button.small
        },
        ModalButton: {
            primary: Shared.Button.primary,
            secondary: Shared.Button.secondary
        },
        WhiteButton: {
            button: Shared.Button.base
        }
    },
    Modal: {
        Modal: {
            modal: Shared.Modal.modal,
            closeIcon: Shared.Modal.close
        }
    },
    BaseComponents: {
        ModalX: {
            closeButton: Shared.Modal.close
        },
        Button: {
            root: Shared.Button.base,
            smallThird: cn(Shared.Button.small, Shared.Button.primary),
            optionAction: cn(Shared.Button.small, Shared.Button.primary),
            optionEdit: cn(Shared.Button.small, Shared.Button.outlined)
        },
        SexSelector: {
            sexSelector__container: Shared.SexSelector.container,
            label: Shared.SexSelector.label
        }
    }
};
export default themeShared;
