import cn from 'classnames';
import OrderCard from './components/OrderCard/OrderCard.css';
import Location from './components/SearchForm/components/SearchForm/Segments/Segment/Location/Location.css';
import Contacts from './components/Contacts/Contacts.css';
import CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService.css';
import Checkout from './components/Checkout/Checkout.css';
import OrderPaidServiceCards from './components/OrderPaidServiceCards/OrderPaidServiceCards.css';
import Toolbar from './components/Toolbar/Toolbar.css';
import PaymentForm from './components/PaymentForm/PaymentForm.css';
import SearchForm from './components/SearchForm/SearchForm.css';
import DesktopFlightRoute from './components/DesktopFlightRoute/DesktopFlightRoute.css';
import Results from './components/Results/Results.css';
import Stepbar from './components/Stepbar/Stepbar.css';
import Insurance from './components/Insurance/Insurance.css';
import FareGroup from './components/FareGroup/FareGroup.css';
import WeekCalendar from './components/WeekCalendar/WeekCalendar.css';
import MobileFlight from './components/MobileFlight/MobileFlight.css';
import FareLock from './components/FareLock/FareLock.css';
import * as Button from './components/Button/Button.css';
import * as Switch from './components/Switch/Switch.css';
import Baggage from './components/Baggage/Baggage.css';
import Seats from './components/Seats/Seats.css';
import Meal from './components/Meal/Meal.css';
import SuggestionModalContent from './components/SuggestionModalContent/SuggestionModalContent.css';
import DesktopFlight from './components/DesktopFlight/DesktopFlight.css';
import FlightCard from './components/FlightCard/FlightCard.css';
import FullscreenDialog from './components/FullscreenDialog/FullscreenDialog.css';
import ModalHeading from './components/ModalHeading/ModalHeading.css';
import MobileSegmentInfo from './components/MobileSegmentInfo/MobileSegmentInfo.css';
import TransferInfo from './components/TransferInfo/TransferInfo.css';
import Checkin from './components/Checkin/Checkin.css';
import Input from './components/Input/Input.css';
import RetrieveBooking from './components/RetrieveBooking/RetrieveBooking.css';
import Segment from './components/Segment/Segment.css';
import MobileSegment from './components/MobileSegment/MobileSegment.css';
import SelectedPassenger from './components/SelectedPassenger/SelectedPassenger.css';
import AddPassenger from './components/AddPassenger/AddPassenger.css';
import StatusMessage from './components/StatusMessage/StatusMessage.css';
import RegisteredPassenger from './components/RegisteredPassenger/RegisteredPassenger.css';
import CheckinRules from './components/CheckinRules/CheckinRules.css';
import Refund from './components/Refund/Refund.css';
import Exchange from './components/Exchange/Exchange.css';
import CommonContent from './components/Exchange/CommonContent/CommonContent.css';
import Passenger from './components/Passenger/Passenger.css';
import LoginForm from './components/LoginPage/components/LoginForm/LoginForm/LoginForm.css';
import VipServiceSelect from './components/VipServiceSelect/components/VipServiceSelect.css';
import PriorityBoarding from './components/CheckoutAdditionalService/components/PriorityBoarding/PriorityBoarding.css';
import CheckoutPassengersToolbar from './components/CheckoutPassengersToolbar/CheckoutPassengersToolbar.css';
import ModalConfirm from './components/ModalConfirm/ModalConfirm.css';
import CheckDataModal from './components/CheckDataModal/CheckDataModal.css';
import CardExtra from './components/CardExtra/CardExtra.css';
import QuickSearchForm from './components/QuickSearchForm/QuickSearchForm.css';
import SelectedFareGroup from './components/SelectedFareGroup/SelectedFareGroup.css';
import DesktopFlightInfo from './components/DesktopFlightInfo/DesktopFlightInfo.css';
import BaseComponents from './components/BaseComponents/BaseComponents.css';
import BaseButton from './components/BaseComponents/Button/Button.css';
import BaseInput from './components/BaseComponents/Input/Input.css';
import Notification from './components/Notification/Notification.css';
import ServiceSummary from './components/ServiceSummary/ServiceSummary.css';
import MobileStepbar from './components/MobileStepbar/MobileStepbar.css';
import PassengerForm from './components/PassengerForm/PassengerForm.css';
import themeShared from './components/themeShared';
import Shared from './components/Shared';
const theme = Object.assign(Object.assign({}, themeShared), { CheckinRules: {
        CheckinRules: {
            rules__text: CheckinRules.webRegistration__conditions
        }
    }, SearchForm: {
        Actions: {
            addSegment: SearchForm.addSegment,
            removeSegment: SearchForm.removeSegment,
            actions_alternative: SearchForm.actions_alternative
        },
        SearchForm: {
            searchForm: SearchForm.searchForm,
            row: SearchForm.row,
            beforeAdornment__wrapper: SearchForm.beforeAdornment__wrapper
        },
        MultiCity: {
            caption: SearchForm.multiCity__caption
        },
        StartSearch: {
            startSearch: SearchForm.startSearch,
            startSearch_iconMode: SearchForm.startSearch_iconMode,
            startSearch_multiCity: SearchForm.startSearch_multiCity
        },
        Bottom: {
            bottom: SearchForm.bottom,
            controls: SearchForm.bottom__controls
        },
        Segment: {
            input: SearchForm.input,
            cell: SearchForm.cell,
            cell_date: SearchForm.cell_date,
            cell_location: SearchForm.cell_location,
            cell_location_arrival: SearchForm.cell_location_arrival,
            location__value: SearchForm.location__value,
            wrapper: SearchForm.segment_wrapper,
            withMultiCity: SearchForm.withMultiCity,
            control: SearchForm.control,
            alternative: SearchForm.segment_alternative
        },
        Controls: {
            controls: SearchForm.controls,
            controls_multiCity: SearchForm.controls_multiCity
        },
        Segments: {
            segments: SearchForm.segments,
            segments_multiCity: SearchForm.segments_multiCity
        },
        Value: {
            wrapper: SearchForm.valueWrapper,
            code: SearchForm.code,
            value: SearchForm.value
        },
        Location: {
            switcher: cn(SearchForm.switcher, Location.switcher),
            switcher_alternative: cn(SearchForm.switcher_alternative, Location.switcher_alternative),
            switcher_highlighted: SearchForm.switcher_highlighted,
            input: SearchForm.location__input,
            input_icon: SearchForm.location__input_icon,
            autocomplete_alternative: SearchForm.autocomplete_alternative
        },
        Datepicker: {
            dates: SearchForm.dates,
            dates__to: SearchForm.dates__to,
            dates__back: SearchForm.dates__back,
            dates_focused: SearchForm.dates_focused,
            returnDelete: SearchForm.returnDelete,
            placeholder: SearchForm.datesPlaceholder,
            dates__to_focused: SearchForm.dates__to_focused,
            dates__back_focused: SearchForm.dates__back_focused,
            dates__icon: SearchForm.dates__icon,
            dow: SearchForm.dow,
            alternative: SearchForm.alternative,
            alternative_dow: SearchForm.alternative_dow,
            highlighted_minPrice: SearchForm.highlightedDate_withPrice
        },
        Passengers: {
            selector: SearchForm.selector,
            passengers: SearchForm.passengers,
            arrowIcon: SearchForm.arrowIcon,
            title: SearchForm.passengerTitle,
            alternative: SearchForm.passengerAlternative,
            mobileTitle: SearchForm.passengersMobileTitle,
            selector_focused: SearchForm.selector_focused,
            passengers_isMultiCity: SearchForm.passengers_isMultiCity
        },
        PayByMiles: {
            switchBox: SearchForm.switchBox,
            switchWrapper: SearchForm.switchWrapper,
            switchActive: SearchForm.switchActive
        }
    }, QuickSearchForm: {
        QuickSearchForm: {
            inner__summary: QuickSearchForm.inner__summary,
            outer__summary: QuickSearchForm.outer__summary,
            summary: QuickSearchForm.summary,
            wrapper: QuickSearchForm.wrapper,
            form__wrapper: SearchForm.quickSearchForm__wrapper,
            form: QuickSearchForm.form
        },
        Summary: {
            route: QuickSearchForm.summary__route,
            dates: QuickSearchForm.summary__dates,
            passengers: QuickSearchForm.summary__passengers,
            editButtonWrapper: QuickSearchForm.summary__editButtonWrapper
        }
    }, Passengers: {
        PassengerStyles: {
            increase: SearchForm.increase,
            decrease: SearchForm.decrease,
            counter: SearchForm.counter,
            counter_disabled: SearchForm.counter_disabled
        }
    }, PassengerForm: {
        PassengerForm: {
            form: PassengerForm.form
        },
        Header: {
            number: PassengerForm.header__number
        },
        Group: {
            container: PassengerForm.group__container,
            identity: cn(PassengerForm.group__icon, PassengerForm.group_identity),
            documents: cn(PassengerForm.group__icon, PassengerForm.group_documents)
        },
        Switch: {
            toggle: PassengerForm.switch__toggle,
            toggle_active: PassengerForm.switch__toggleActive
        },
        Disabilities: {
            switchControl: PassengerForm.disabilities__switchControl
        }
    }, 
    // Button: {
    // 	Button: {
    // 		button_flat: SearchForm.button_flat,
    // 		button: Button.button,
    // 		button_large: SearchForm.button_large
    // 	},
    // 	ActionButton: {
    // 		button: Button.action
    // 	},
    // 	BaseButton: {
    // 		button: Baggage.button
    // 	},
    // 	WhiteButton: {
    // 		button: Button.button_white
    // 	}
    // },
    SearchFormDatepicker: {
        DayStyles: {
            day_withPrice: SearchForm.day_withPrice,
            day_endHover_withPrice: SearchForm.day_endHover_withPrice,
            day_startHover_withprice: SearchForm.day_startHover_withprice,
            day_selected: SearchForm.day_selected,
            day_inPeriod: SearchForm.day_inPeriod,
            day_weekend: SearchForm.day_weekend,
            day_today: SearchForm.day_today,
            day__price: SearchForm.day__price
        },
        MonthStyles: {
            month: SearchForm.month
        },
        MonthsWrapperStyles: {
            nav_prev: SearchForm.nav_prev
        },
        SearchFormDatepickerStyles: {
            content: SearchForm.content,
            tabs: SearchForm.tabs,
            footer: SearchForm.footer,
            footer__buttons: SearchForm.footer__buttons,
            footer__done: SearchForm.footer__done,
            datepicker__disclaimer: SearchForm.datepicker__disclaimer,
            popup: SearchForm.popup,
            progress__root: SearchForm.progress__root,
            footer__clear: SearchForm.footer__clear,
            dialog__bar: SearchForm.dialog__bar,
            dialog__dates: SearchForm.dialog__dates,
            dialog__activeDate: SearchForm.dialog__activeDate,
            dialog__dateBack: SearchForm.dialog__dateBack,
            dialog__close: SearchForm.dialog__close
        },
        WeeklyHeaderStyles: {
            dayOfWeekName_withPrices: SearchForm.dayOfWeekName_withPrices
        }
    }, Datepicker: {
        DayStyles: {
            day_notAvailable: SearchForm.day_notAvailable
        },
        MonthHeaderStyles: {
            monthHeader__select_month: SearchForm.monthHeader__monthHeaderSelectMonth,
            monthHeader__select_year: SearchForm.monthHeader__monthHeaderSelectYear,
            monthHeader: SearchForm.monthHeader
        }
    }, LocationAutocomplete: {
        Group: {
            group__label: SearchForm.group__label
        },
        Option: {
            label: SearchForm.option__label
        },
        Autocomplete: {
            autocomplete: SearchForm.autocomplete
        }
    }, DesktopFlightRoute: {
        DesktopFlightRouteStyles: {
            flight: cn(DesktopFlightRoute.flight, Exchange.flight),
            selected: DesktopFlightRoute.selected
        },
        FlightPriceStyles: {
            button: DesktopFlightRoute.flightPrice,
            fare_family: DesktopFlightRoute.flightPrice__fareFamily,
            price__money: DesktopFlightRoute.flightPrice__priceMoney,
            price: DesktopFlightRoute.flightPrice__price,
            price_selected: DesktopFlightRoute.flightPrice__priceSelected
        },
        RouteArrowStyles: {
            flightNumber: cn(DesktopFlightRoute.flightNumber, Checkout.flightNumber)
        },
        SegmentStyles: {
            flightInfo: DesktopFlightRoute.segment__flightInfo,
            flightInfo__date: DesktopFlightRoute.flightInfo__date,
            aircraft: DesktopFlightRoute.segment__aircraft,
            segment: DesktopFlightRoute.segment__segment,
            oneStop: DesktopFlightRoute.segment__oneStop
        },
        PointStyles: {
            point: DesktopFlightInfo.point,
            mobile: DesktopFlightInfo.mobile
        },
        StopsInfoStyles: {
            stop: DesktopFlightInfo.stopsInfo__stop
        }
    }, MobileFlight: {
        FlightStyles: {
            flight: MobileFlight.flight,
            flight_selected: MobileFlight.flight_selected,
            readonly: MobileFlight.readonly,
            price: MobileFlight.flightPrice,
            selectedFare__price: Checkout.selectedFare__price,
            selectedFare: Checkout.selectedFare,
            price__money: Checkout.price__money
        }
    }, MobileStepbar: {
        MobileStepbarStyles: {
            stepbar__cartWrapper: cn(MobileStepbar.stepbar__cartWrapper, ServiceSummary.serviceSummary_cart)
        },
        StepbarServiceSummary: {
            container: ServiceSummary.wrapper,
            header: ServiceSummary.header,
            header__title: ServiceSummary.header__title,
            header__price: ServiceSummary.header__price,
            content: ServiceSummary.content,
            button: cn(Shared.Button.outlined),
            button_add: cn(Shared.Button.primary, ServiceSummary.button_add)
        }
    }, Results: {
        Results: {
            inner: Results.inner,
            outer_bottom: Results.outer,
            share: Results.shareButton
        },
        Calendar: {
            legNumber: Results.legNumber,
            diagram: Results.diagram,
            tabs: Results.calendar_tabs
        },
        SelectedFares: {
            flight: Results.selectedFlights,
            title: Results.selectedFlights__title,
            wrapper: Results.selectedFares__wrapper
        },
        FlightsList: {
            wrapper: Results.flightList
        },
        OtherDates: {
            container: Results.otherDates,
            text: Results.text
        },
        Sortings: {
            flightType: Results.sortings__flightType,
            flightType__active: Results.sortings__flightType_active,
            currentSorting: Results.currentSorting
        }
    }, MobileSelectedFare: {
        MobileSelectedFareStyles: {
            selectedFare: Results.mobileSelectedFares
        }
    }, FareLock: {
        FareLock: {
            root: FareLock.wrapper,
            switch_block: FareLock.switchBlock,
            description_block: FareLock.description_block
        },
        DescriptionBlock: {
            title: FareLock.title,
            description: FareLock.description,
            icon: FareLock.icon
        }
    }, Stepbar: {
        Stepbar: {
            stepbar: Stepbar.stepbar,
            inner: Stepbar.inner,
            cart: Stepbar.cart
        },
        Step: {
            step: Stepbar.step,
            id: Stepbar.id,
            step_active: Stepbar.active,
            step_disabled: Stepbar.disabled,
            arrow: Stepbar.arrow
        }
    }, FareGroup: {
        FareGroup: {
            fareGroup: FareGroup.fareGroup,
            keyFeature: FareGroup.keyFeature,
            keyFeature_paid: FareGroup.keyFeature__paid,
            fareGroup_white: FareGroup.fareGroup_white,
            option: FareGroup.option,
            option_unavailable: FareGroup.option_unavailable,
            option_free: FareGroup.option_free,
            option__title: FareGroup.option_title,
            option__value: FareGroup.option_value,
            fareGroup_Business: FareGroup.fare_business,
            button: FareGroup.button,
            price: FareGroup.price
        },
        FareGroupGrid: {
            upgradeOffer: FareGroup.fareGroupGrid__upgradeOffer,
            recommended: FareGroup.fareGroup__recommend,
            small: FareGroup.fareOption_small,
            paid: FareGroup.fareOption_paid,
            option: FareGroup.fareOption,
            not_available: FareGroup.fareOption_unavailable,
            header: FareGroup.header
        }
    }, CompareFares: {
        CompareFares: {
            cell: Results.compareFares__cell,
            image: Results.compareFares__image,
            content: Results.compareFares__content,
            tableRow: Results.compareFares__tableRow,
            title: Results.compareFares__title,
            cellContent: Results.compareFares__cellContent,
            fareHeadingItem: Results.compareFares__fareHeadingItem,
            fareHeadingItem_isKey: Results.compareFares__fareHeadingItemIsKey,
            slider: Results.compareFares__slider,
            groupTitle: Results.compareFares__groupTitle
        }
    }, WeekCalendar: {
        DayStyles: {
            price: WeekCalendar.price,
            day_selected: WeekCalendar.active,
            day: WeekCalendar.day,
            date: WeekCalendar.date,
            date__info: WeekCalendar.date__info
        },
        WeekCalendarStyles: {
            dayWrapper: WeekCalendar.day__wrapper,
            container: WeekCalendar.container
        },
        SliderStyles: {
            arrow: WeekCalendar.arrow
        }
    }, Passenger: {
        Switch: {
            container: Switch.container,
            element: Switch.element,
            element_active: Switch.element_active,
            element_error: Switch.element_error
        },
        PassengerStyles: {
            form__field: Passenger.form__field,
            form_citizenship: Passenger.form_citizenship
        }
    }, OrderCard: {
        OrderCard: {
            container: OrderCard.orderCard__container,
            type_review: OrderCard.orderCard__typeReview,
            root: OrderCard.orderCard__root
        },
        OrderPreview: {
            root: OrderCard.orderPreview__root,
            city: OrderCard.orderPreview__city,
            main_city: OrderCard.orderPreview__mainCity,
            overlay: OrderCard.orderPreview__overlay,
            period: OrderCard.orderPreview__period
        },
        OrderBooking: {
            orderCode: OrderCard.orderBooking__orderCode,
            security_code: OrderCard.orderBooking__securityCode,
            price: OrderCard.orderBooking__price,
            download: OrderCard.orderBooking__download,
            type_review: OrderCard.orderBooking__typeReview,
            root: OrderCard.orderBooking__root
        },
        CommonItem: {
            root: OrderCard.commonItem__root,
            review: OrderCard.commonItem__rootReview,
            icon: OrderCard.commonItem__icon
        },
        PaymentStatus: {
            root: OrderCard.paymentStatus__root,
            icon: OrderCard.paymentStatus__icon
        },
        OrderStatus: {
            root: OrderCard.orderStatus__root,
            type_review: OrderCard.orderStatus__typeReview,
            confirmed: OrderCard.orderStatus__confirmed,
            icon: OrderCard.orderStatus__icon
        },
        OrderChange: {
            button: cn(Shared.Button.outlined, OrderCard.orderChange__button)
        },
        OrderCardControls: {
            root: OrderCard.orderCardControls__root,
            button_checkin: cn(Shared.Button.primary)
        }
    }, CheckoutAdditionalService: {
        PriorityBoarding: {
            priorityBoardingModal: PriorityBoarding.priorityBoardingModal,
            header: PriorityBoarding.header
        }
    }, CardExtra: {
        CardExtraContent: {
            root: CardExtra.root,
            root_seats: CardExtra.root_seats,
            root_baggage: CardExtra.root_baggage,
            root_meal: CardExtra.root_meal,
            root_insurance: CardExtra.root_insurance,
            root_businessLounge: CardExtra.root_businessLounge,
            root_priorityBoarding: CardExtra.root_priorityBoarding,
            root_transfer: CardExtra.root_transfer,
            root_loyalty: CardExtra.root_loyalty,
            header: CardExtra.header,
            header__title: CardExtra.header__title,
            header__icon: CardExtra.header__icon,
            description: CardExtra.description,
            button_add: CardExtra.button_add
        },
        CardExtraModal: {
            root: CardExtra.modal__root,
            header: CardExtra.modal__header
        }
    }, Cart: {
        Cart: {
            cart: cn(ServiceSummary.serviceSummary_cart)
        }
    }, ServiceSummary: {
        ServiceSummary: {
            wrapper: ServiceSummary.wrapper,
            header: ServiceSummary.header,
            header__title: ServiceSummary.header__title,
            header__price: ServiceSummary.header__price,
            content: ServiceSummary.content
        },
        ServiceSummaryOption: {
            wrapper: ServiceSummary.option__wrapper
        }
    }, Checkout: {
        OrderPaidServiceCards: {
            baggagePage__baggage: OrderPaidServiceCards.baggagePage,
            meal__dialogContent: OrderPaidServiceCards.meal__dialogContent
        },
        CheckoutStyles: {
            container: Checkout.checkout__container,
            checkoutServices: Checkout.checkout__services,
            summary__header: Checkout.checkout__summaryHeader,
            summary: Checkout.checkout__summary,
            flight: Checkout.checkout__flight,
            desktopFlight__flight: Checkout.checkout__flight,
            gdsTotalPrice: Checkout.gdsTotalPrice,
            notice: Checkout.notice,
            selectedFlight__container: cn(Checkout.selectedFlight),
            form: Checkout.checkout__form
        },
        ThanksBanner: {
            wrapper: Checkout.thanksBanner__wrapper,
            title: Checkout.thanksBanner__title
        },
        PassengersToolbar: {
            label: CheckoutPassengersToolbar.label,
            toolbarCheckbox__wrapper: Checkout.toolbar__checkbox
        },
        CheckDataModal: {
            paper: CheckDataModal.paper
        }
    }, ModalConfirm: {
        ModalConfirm: {
            header: ModalConfirm.header,
            title: ModalConfirm.title,
            buttons: ModalConfirm.buttons,
            content: ModalConfirm.content,
            footer: ModalConfirm.footer,
            cancelButton: ModalConfirm.cancelButton,
            confirmButton: cn(Button.smallThird, BaseButton.button)
        }
    }, BaseComponents: Object.assign(Object.assign({}, themeShared.BaseComponents), { Input: {
            input: cn(BaseInput.input, BaseComponents.input),
            labelIn: BaseInput.labelIn,
            label: BaseInput.label
        } }), PaymentForm: {
        PaymentForm: {
            wrapper: PaymentForm.payment__wrapper,
            details: PaymentForm.payment__details,
            details__button: PaymentForm.payment__details__button,
            content: PaymentForm.payment__content,
            content__title: PaymentForm.payment__content__title,
            oneMethod: PaymentForm.payment__oneMethod,
            cards: PaymentForm.cards,
            card: PaymentForm.card,
            gateways: PaymentForm.gateways,
            gateways_hidden: PaymentForm.gateways_hidden
        },
        Summary: {
            group: PaymentForm.payment__group,
            item: PaymentForm.payment__item
        },
        Card: {
            iframe: PaymentForm.iframe
        },
        Gateway: {
            header__arrow: PaymentForm.header__arrow,
            header: PaymentForm.header,
            selector: PaymentForm.selector,
            arrow: PaymentForm.arrow
        }
    }, PaymentTimeLimit: {
        PaymentTimeLimit: {
            wrapper: PaymentForm.payment__timeLimit
        }
    }, IncludedBaggage: {
        IncludedBaggage: {
            header: Baggage.header,
            root: Baggage.root,
            included_block: Baggage.included_block
        }
    }, AdditionalBaggage: {
        AdditionalBaggage: {
            root: Baggage.root,
            body: Baggage.body,
            header: Baggage.header
        },
        PromoMessage: {
            root: Baggage.promo_message
        }
    }, Baggage: {
        BaggageIcon: {
            hand_luggage_excess: Baggage.handLuggageExcess,
            carry_on: Baggage.carryOn,
            wrapper: Baggage.iconWrapper,
            size: cn(Baggage.iconSize),
            root: Baggage.iconRoot,
            value: Baggage.value,
            measurement: Baggage.measurement
        },
        Baggage: {
            name: Baggage.name,
            size: Baggage.size
        }
    }, OverweightBaggage: {
        OverweightBaggage: {
            flight_count: Baggage.flightCount,
            flight_direction: Baggage.flightDirection
        }
    }, SpecialEquipment: {
        SpecialEquipment: {
            flight_count: Baggage.flightCount,
            flight_direction: Baggage.flightDirection
        }
    }, BaggageList: {
        BaggageCount: {
            root: Baggage.counter
        },
        BaggageDirection: {
            root: Baggage.direction
        },
        BaggageList: {
            item: Baggage.item,
            root: Baggage.baggageList,
            add: Baggage.add,
            price: Baggage.baggageListPrice
        }
    }, BaggageTotalPriceBlock: {
        BaggageTotalPriceBlock: {
            price: Baggage.price
        }
    }, BaggagePage: {
        BaggagePage: {
            items: Baggage.items,
            equipment_baggage: Baggage.equipmentBaggage
        }
    }, BaggageCounter: {
        BaggageCounter: {
            minus: Baggage.counterButton,
            plus: Baggage.counterButton,
            disabled: Baggage.counterButton_disabled,
            value: Baggage.counterButton_value
        }
    }, LoginPage: {
        LoginForm: {
            row: LoginForm.row
        }
    }, BaggageCard: {
        BaggageCard: {
            icon: Baggage.cardIcon,
            wrapper: Baggage.cardWrapper,
            price: Baggage.price,
            title: Baggage.title
        }
    }, UpgradeBaggageDialog: {
        BaggagePassenger: {
            name: Baggage.passengerName,
            checkbox_root: Baggage.checkbox_root,
            checkbox_rootChecked: Baggage.checkbox_rootChecked,
            number: Baggage.passengerNumber,
            counter_wrapper: Baggage.counter_wrapper
        },
        BaggageFooter: {
            clear: Baggage.clear,
            price_wrapper: Baggage.price_wrapper
        },
        BaggageDialogHeader: {
            perFlight: Baggage.perFlight,
            baggage_wrapper: Baggage.baggage_wrapper,
            category_title: Baggage.category_title,
            category__price: Baggage.category__price
        },
        UpgradeBaggageDialog: {
            modalHeader: Baggage.modalHeader,
            close: Baggage.close
        },
        BaggageSegment: {
            root: Baggage.segment,
            name: Baggage.flightName
        },
        MobileBaggageSelector: {
            footer: Baggage.mobileSelector,
            description: Baggage.mobileSelector__description
        },
        HeaderEquipmentItem: {
            selected: Baggage.headerEquipmentItem__selected,
            wrapper: Baggage.headerEquipmentItem__wrapper
        }
    }, Toolbar: {
        Toolbar: {
            price: Baggage.toolPrice,
            backButton: cn(Baggage.backButton, Toolbar.toolbar__backButton),
            backButton__wrapper: Baggage.backButton__wrapper,
            toolbar: Toolbar.toolbar__container,
            inner: Toolbar.toolbar__inner,
            actions: Toolbar.toolbar__actions
        }
    }, Switch: {
        SwitchV2: {
            primary: Baggage.switcherPrimary,
            active: Baggage.switcherPrimary_active
        }
    }, AdditionalServiceBanner: {
        AdditionalServiceBannerStyles: {
            baggageBanner: Baggage.baggageBanner,
            extrasBanner: Checkin.extrasBanner
        }
    }, Insurance: {
        InsuranceStyles: {
            dialog: Insurance.insurance__dialog
        },
        MobileScreenStyles: {
            header: Checkin.insurance__header
        },
        InsuranceCardStyles: {
            insurance__header: Insurance.insuranceCard__header,
            insurance__button: cn(Checkin.insurance__button, Insurance.insuranceCard__button),
            insurance__button_selected: Insurance.insuranceCard__button_selected
        }
    }, InsurancePage: {
        InsurancePage: {
            wrapper: Insurance.insurancePage__wrapper,
            subheader: Insurance.insurancePage__subheader
        },
        InsurancePrice: {
            button: Insurance.insurancePrice__button,
            label: Insurance.insurancePrice__label,
            light: Insurance.insurancePrice__label
        },
        InsuranceProduct: {
            title: Insurance.insuranceProduct__title,
            popular: Insurance.insuranceProduct_popular,
            selectButton: Insurance.insuranceProduct__selectButton,
            content: Insurance.insuranceProduct__content
        },
        SelectForm: {
            form: Insurance.selectForm__form,
            price: Insurance.selectForm__price,
            priceContent: Insurance.selectForm__priceContent
        }
    }, ResendTicketsForm: {
        ResendTicketsForm: {
            wrapper: Checkin.resendTicket__wrapper,
            submit: Checkin.resendTicket__submit,
            cancel: Checkin.resendTicket__cancel
        }
    }, BaggageService: {
        BaggageServiceStyles: {
            baggage_full: Baggage.baggage_full,
            baggage__icon_notSelected: Baggage.baggage__icon_notSelected,
            baggage: Baggage.baggage,
            carryon: Baggage.carryon,
            service__price: Baggage.service__price,
            service__values: Baggage.service__values,
            overweight__disabled: Baggage.overweight__disabled,
            add__button: Baggage.add__button
        }
    }, SeatMap: {
        SeatStyles: {
            seat: Seats.seat,
            seat_occupied: Seats.seat_occupied,
            seat_preferred: Seats.seat_preferred,
            seat_business: Seats.seat_business,
            seat_confirmed: Seats.seat_confirmed,
            seat_recommend: Seats.seat_recommend,
            seat_recommendCurrent: Seats.seat_recommendCurrent,
            number: Seats.number,
            seat_free: Seats.seat_free,
            seat_combinedLeft: Seats.seat_combinedLeft,
            seat_combinedRight: Seats.seat_combinedRight,
            seat_unavailable: Seats.seat_unavailable
        },
        SeatMapStyles: {
            row: Seats.seatMap_row,
            wings: Seats.seatMap_wings
        },
        SeatsInfoStyles: {
            title: Seats.title,
            seat_economy: Seats.seat_economy,
            seat_preferred: Seats.seat_preferred,
            seat_business: Seats.seat_business,
            classInfo: Seats.classInfo,
            seat: Seats.seat,
            price__wrapper: Seats.price__wrapper,
            description: Seats.seatsInfo__description,
            seat__info: Seats.seat__info,
            image: Seats.image
        },
        SeatTooltipStyles: {
            tooltip: Seats.tooltip
        },
        Passenger: {
            item: Seats.item,
            item_recommend: Seats.item_recommend,
            item_active: Seats.item_active,
            passengerName: Seats.passengerName,
            money: Seats.money,
            select: Seats.select,
            payload: Seats.payload,
            avatar: Seats.avatar
        },
        PassengersStyles: {
            recommend__wrapper: Seats.recommend__wrapper,
            passengers__button: Seats.passengers__button,
            passengers__button_clear: Seats.passengers__button_clear,
            recommend: Seats.recommend
        },
        AirplaneStyles: {
            airplane: Seats.airplane,
            airplane__name: Seats.airplane__name
        },
        AirplaneInfoStyles: {
            sunInfo: Seats.sunInfo,
            sunInfo__inner: Seats.sunInfo__inner
        },
        Controls: {
            button: Seats.controlsButton
        },
        SeatMapDisclaimerStyles: {
            disclaimer__wrp: Seats.disclaimer__wrp
        }
    }, Disclaimer: {
        DisclaimerStyles: {
            disclaimer__icon: Seats.disclaimer__icon
        }
    }, SegmentTabs: {
        SegmentTab: {
            segment: Seats.segment,
            checked: Seats.checked,
            segment_active: Seats.segment_active
        }
    }, Amenities: {
        Amenities: {
            amenities__item: cn(Seats.amenities__item, DesktopFlightRoute.amenities__item),
            amenities__item_unavailable: DesktopFlightRoute.unavailable
        }
    }, Meal: {
        Meal: {
            meal: Meal.meal,
            meal_fullSize: Meal.meal_fullSize,
            name: Meal.name,
            price: Meal.price,
            image: Meal.image,
            title: Meal.title,
            header: Meal.header,
            marker: Meal.marker,
            footer: Meal.footer,
            footer__icons: Meal.footer__icons,
            count: Meal.count,
            popup: Meal.popup
        },
        Details: {
            image: Meal.image,
            marker: Meal.marker,
            button: Meal.details__button,
            button_empty: Meal.details__buttonEmpty,
            totalText: Meal.details__totalText,
            total: Meal.details__total
        }
    }, MealService: {
        List: {
            wrapper: Meal.list__wrapper,
            item: Meal.list__item,
            item_selected: Meal.list__itemSelected,
            item__icon: Meal.item__icon
        },
        CategoriesStyles: {
            wrapper: Meal.categories__wrapper,
            category__button: Meal.categories__categoryButton
        }
    }, ServiceWithSegments: {
        ServiceWithSegments: {
            wrapper: Checkout.serviceWithSegments__wrapper
        }
    }, CloseIcon: {
        CloseIcon: {
            wrapper: Checkout.closeButton
        }
    }, Counter: {
        Counter: {
            button: Checkout.counter__button,
            button_disabled: Checkout.counter__buttonDisabled
        }
    }, Contacts: {
        ContactsStyles: {
            contacts: cn(Contacts.contacts, Checkout.checkout__contacts),
            description__text: Contacts.description__text,
            footer__inner: Contacts.footer__inner,
            footer_login: Contacts.footer_login
        }
    }, SuggestionModalContent: {
        SuggestionModalContent: {
            modal__button: SuggestionModalContent.suggestionModal__button,
            modal__button_decline: SuggestionModalContent.suggestionModal__buttonDecline,
            modal__text: SuggestionModalContent.suggestionModal__text
        }
    }, DesktopFlight: {
        ModalContent: {
            content: DesktopFlight.modalContent__content,
            fareGroup__button: Results.sortings__flightType,
            fareGroup__button_active: Results.sortings__flightType_active
        },
        Location: {
            airport: DesktopFlight.location__airport
        },
        FlightInfoSegment: {
            flight: DesktopFlight.flightInfoSegment__flight,
            isMobile: DesktopFlight.flightInfoSegment__isMobile,
            locations: DesktopFlight.flightInfoSegment__locations,
            card: DesktopFlight.flightInfoSegment__card
        },
        SelectedFareGroup: {
            button: SelectedFareGroup.button
        }
    }, FlightCard: {
        FlightCard: {
            info: FlightCard.info,
            aircraft__name: FlightCard.flightCard__aircraftName
        }
    }, FullscreenDialog: {
        FullscreenDialog: {
            close__icon: FullscreenDialog.fullscreenDialog__closeIcon
        }
    }, ModalHeading: {
        ModalHeading: {
            heading: ModalHeading.heading
        }
    }, MobileSegmentInfo: {
        MobileSegmentFlat: {
            aircraft: MobileSegmentInfo.aircraft,
            image: MobileSegmentInfo.image,
            totalDuration: MobileSegmentInfo.segmentFlat__totalDuration
        }
    }, TransferInfo: {
        TransferInfo: {
            transfer: TransferInfo.transfer,
            warning: TransferInfo.warning
        }
    }, Checkin: {
        Inital: {
            wrapper: Checkin.initial__wrapper,
            form: Checkin.initial__form
        },
        Passengers: {
            wrapper: Checkin.passengers__wrapper,
            content: Checkin.passengers__content,
            segments: Checkin.passengers__segments,
            checkinCancelModal__content: Checkin.checkinCancelModal__content
        },
        Checkin: {
            servicePrice: Checkin.checkin__servicePrice
        },
        PassengersHeader: {
            container: Checkin.passengersHeader__container,
            title: Checkin.passengersHeader__title
        },
        PassengersContent: {
            passengers: Checkin.passengersContent__passengers
        },
        Order: {
            container: Checkin.order__container,
            stepBar: Checkin.order__stepBar
        },
        SelectedService: {
            service: Checkin.selectedService__service
        }
    }, RetrieveBooking: {
        RetrieveBooking: {
            container: RetrieveBooking.retrieveBooking__container,
            container_checkin: RetrieveBooking.retrieveBooking__container_checkin,
            title: RetrieveBooking.retrieveBooking__title,
            form: RetrieveBooking.retrieveBooking__form
        }
    }, StatusMessage: {
        StatusMessage: {
            statusMessage: StatusMessage.statusMessage__wrapper,
            statusMessage_success: StatusMessage.statusMessage__success,
            statusMessage__title: StatusMessage.statusMessage__title,
            statusMessage__text: StatusMessage.statusMessage__text
        }
    }, Segment: {
        SegmentCommon: {
            wrapper: Segment.segmentCommon__wrapper,
            wrapper_business: Segment.segmentCommon__wrapperBusiness,
            header: Segment.segmentCommon__header,
            city__weather: Segment.segmentCommon__cityWeather,
            flightInfo__item: Segment.segmentCommon__flightInfoItem,
            flightInfo__title: Segment.segmentCommon__flightInfoTitle,
            flightInfo__value: Segment.segmentCommon__flightInfoValue,
            city__airport: Segment.segmentCommon__cityAirport,
            city__info: Segment.segmentCommon__cityInfo,
            city__iata: Segment.segmentCommon__cityIata,
            city__time: Segment.segmentCommon__cityTime,
            plane__icon: Segment.segmentCommon__planeIcon,
            airline__name: Segment.segmentCommon__airlineName,
            footer__item: Segment.segmentCommon__footerItem,
            footer__itemTitle: Segment.segmentCommon__footerItemTitle,
            footer__itemValue: Segment.segmentCommon__footerItemValue
        },
        BoardingPassDesk: {
            wrapper: Segment.boardingPassDesk__wrapper
        }
    }, RegisteredPassenger: {
        RegisteredPassenger: {
            wrapper: RegisteredPassenger.registeredPassenger__wrapper,
            header: RegisteredPassenger.registeredPassenger__header
        },
        Controls: {
            container: RegisteredPassenger.controls__container
        },
        SendToEmail: {
            text: RegisteredPassenger.sendToEmail__text
        }
    }, Input: {
        Input: {
            root: Input.root,
            real_input: Input.real_input,
            input: Input.input,
            input_standard: Input.input_standard,
            input_outline: Input.input_outline,
            textField_filled: Input.textField_filled,
            textField__root: Input.textField__root,
            label_error: Input.label_error,
            textField: Input.textField,
            input_focused: Input.input_focused,
            disabled: Input.disabled,
            input_filled: Input.input_filled
        }
    }, MobileSegment: {
        MobileSegment: {
            wrapper: MobileSegment.mobileSegment__wrapper,
            info: MobileSegment.mobileSegment__info,
            arrivalInfo: MobileSegment.mobileSegment__arrivalInfo
        }
    }, SelectedPassenger: {
        SelectedPassenger: {
            container: SelectedPassenger.selectedPassenger__container,
            passengerInfo: SelectedPassenger.selectedPassenger__passengerInfo,
            passengerInfo__item: SelectedPassenger.selectedPassenger__passengerInfoItem
        }
    }, AddPassenger: {
        AddPassenger: {
            wrapper: AddPassenger.addPassenger__wrapper,
            form__wrapper: AddPassenger.addPassenger__formWrapper,
            form__title: AddPassenger.addPassenger__formTitle,
            form: AddPassenger.addPassenger__form,
            addButton: AddPassenger.addPassenger__addButton,
            addButton__icon: AddPassenger.addPassenger__addButtonIcon,
            submitButton: AddPassenger.addPassenger__submitButton,
            passengerIcon: AddPassenger.addPassenger__passengerIcon,
            form__input: AddPassenger.addPassenger__formInput,
            conditionButton: AddPassenger.addPassenger__conditionButton
        }
    }, ServiceRecommendation: {
        Base: {
            wrapper: Checkin.serviceRecommendation
        }
    }, Notification: {
        Notification: {
            wrapper: Notification.notification__wrapper,
            icon: Notification.notification__icon
        }
    }, PaymentResult: {
        PaymentResult: {
            header: Checkin.payment__header,
            wrapper: Checkin.payment__wrapper
        }
    }, Refund: {
        Refund: {
            buttons: Refund.buttons
        },
        RefundPaper: {
            paper: Refund.paper
        },
        Breadcrumbs: {
            breadcrumb: Refund.breadcrumb
        },
        StepTitle: {
            icon: Refund.stepTitle_icon,
            text: Refund.stepTitle_text
        },
        ReasonForm: {
            groupTitle: Refund.groupTitle,
            reason: Refund.reason,
            active: Refund.reason_active
        },
        Reason: {
            label: Refund.reason_label,
            active: Refund.reason_active
        },
        Passenger: {
            label: Refund.passenger_label,
            passenger: Refund.passenger,
            active: Refund.passenger_active
        },
        Wrapper: {
            title: Refund.wrapper_title,
            header_wrapper: Refund.header_wrapper
        },
        RequestForm: {
            formLabel: Refund.formLabel
        },
        Ticket: {
            title: Refund.ticket_title
        },
        Price: {
            total: Refund.price_total
        },
        RefundInfoModal: {
            button: Refund.refundInfoModal__button,
            no: Refund.refundInfoModal__button_no
        },
        StepsButtons: {
            button: Refund.stepButtons_button
        },
        Status: {
            waiting: Refund.status_waiting,
            icon: Refund.status_icon
        },
        OrderRefundStatus: {
            status: Refund.orderRefundStatus__container,
            success: Refund.orderRefundStatus_success,
            rejected: Refund.orderRefundStatus_rejected,
            processing: Refund.orderRefundStatus_processing,
            waiting: Refund.orderRefundStatus_waiting,
            title: Refund.orderRefundStatus__title,
            button: Refund.orderRefundStatus__button
        }
    }, RefundSummary: {
        CommonContent: {
            serviceCard: CommonContent.serviceCard,
            name: CommonContent.name,
            passengerName: CommonContent.passengerName,
            price: CommonContent.price,
            totalRefund: CommonContent.totalRefund,
            serviceCard_meal: CommonContent.serviceCard_meal,
            serviceCard_seats: CommonContent.serviceCard_seats,
            serviceCard_baggage: CommonContent.serviceCard_baggage
        },
        Notice: {
            container: Notification.notice_container,
            icon: Notification.notice_icon
        }
    }, Exchange: {
        CommonSelect: {
            button: Exchange.button,
            header: Exchange.header,
            back: Exchange.button_back,
            buttons: Exchange.buttons
        },
        CreateRequest: {
            submit: Exchange.createRequest_submit,
            container: Exchange.createRequest_container,
            controls: Exchange.controls
        },
        SelectedFlights: {
            total: Exchange.total,
            total__money: Exchange.total__money,
            charge: Exchange.charge,
            header__name: Exchange.header__name,
            change: Exchange.change,
            header: Exchange.header,
            flight__container: Exchange.flight__container,
            price: Exchange.selectedFlights__price,
            flights: Exchange.selectedFlights__flights
        },
        Choice: {
            labelInner: Exchange.labelInner,
            label: Exchange.label,
            label_selected: Exchange.label_selected,
            container: Exchange.choice_container
        },
        Chip: {
            chip: Exchange.chip,
            close: Exchange.chip__close
        },
        Inner: {
            header__text: Exchange.header__text,
            header: Exchange.inner_header,
            header__inner: Exchange.header__inner,
            closeIcon: Exchange.closeIcon,
            close: Exchange.close
        },
        Sort: {
            title: Exchange.sort_title
        },
        RequestForm: {
            order: Exchange.requestForm_order,
            header: Exchange.requestForm_header
        },
        RequestControls: {
            button: Exchange.requestControls_button,
            container: Exchange.requestControls_container
        },
        Calendar: {
            calendar: Exchange.calendar,
            closeIcon: Exchange.calendar__closeIcon
        },
        SearchFlights: {
            leg: Exchange.leg
        }
    }, VipServiceSelect: {
        VipServiceSelect: {
            cancel: VipServiceSelect.cancel
        }
    }, AdditionalServiceWrapper: {
        AdditionalServiceWrapper: {
            header: CheckoutAdditionalService.additionalServiceWrapper__header
        }
    }, Autocomplete: {
        Autocomplete: {
            options: cn(PassengerForm.autocomplete__options)
        }
    } });
export default theme;
